import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// Import Images
import MyWebviteLogo from '../images/mywebvite-logo.svg'

const Header = ({ siteTitle }) => (
  <div className="header">
    <div className="container">
      <Link to="/" className="logo">
        <img src={MyWebviteLogo} alt="MyWebvite Logo" />
      </Link>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
